import getData from "@/__main__/get-data.mjs";
import LolProbuildsChampionsReport from "@/game-lol/models/lol-probuilds-champions-report.mjs";
import * as API from "@/game-lol/utils/api.mjs";

function fetchData(_params) {
  getData(
    API.getProbuildChampionsReport(),
    LolProbuildsChampionsReport,
    ["lol", "proBuildChampionsReport"],
    {
      shouldFetchIfPathExists: false,
    },
  );
}

export default fetchData;
